var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import React, { useEffect, useState, useCallback } from 'react';
import { useMapsLibrary } from '@vis.gl/react-google-maps';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { Combobox } from '@headlessui/react';
import useDebounce from '../../utilities/useDebounce';
export var addressDetailsClasses = 'focus:ring-violet focus:ring-2 focus:ring-offset-2 shadow-sm block w-full sm:text-sm border-gray-300 focus:border-gray-300 disabled:bg-gray-100 disabled:cursor-not-allowed rounded-lg text-gray-900';
export var GoogleAddressAutoComplete = function (_a) {
    var onPlaceSelect = _a.onPlaceSelect, countryCode = _a.countryCode;
    var places = useMapsLibrary('places');
    var t = useTranslation().t;
    var _b = useState(''), inputValue = _b[0], setInputValue = _b[1];
    var _c = useState(null), selectedPlace = _c[0], setSelectedPlace = _c[1];
    var debouncedInputValue = useDebounce(inputValue, 500);
    var _d = useState(), sessionToken = _d[0], setSessionToken = _d[1];
    var _e = useState(null), autocompleteService = _e[0], setAutocompleteService = _e[1];
    var _f = useState(null), placesService = _f[0], setPlacesService = _f[1];
    var _g = useState([]), predictionResults = _g[0], setPredictionResults = _g[1];
    useEffect(function () {
        if (!places) {
            return;
        }
        setAutocompleteService(new places.AutocompleteService());
        setPlacesService(new places.PlacesService(document.getElementById('googlemaps-attribution-container')));
        setSessionToken(new places.AutocompleteSessionToken());
        return function () { return setAutocompleteService(null); };
    }, [places]);
    var fetchPredictions = useCallback(function (inputValue) { return __awaiter(void 0, void 0, void 0, function () {
        var request, response;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (!autocompleteService || !inputValue) {
                        setPredictionResults([]);
                        return [2 /*return*/];
                    }
                    request = { input: inputValue, region: "" + (countryCode !== null && countryCode !== void 0 ? countryCode : 'GB'), sessionToken: sessionToken };
                    return [4 /*yield*/, autocompleteService.getPlacePredictions(request)];
                case 1:
                    response = _a.sent();
                    setPredictionResults(response.predictions);
                    return [2 /*return*/];
            }
        });
    }); }, [autocompleteService, sessionToken]);
    useEffect(function () {
        if (debouncedInputValue) {
            fetchPredictions(debouncedInputValue);
        }
        else {
            setPredictionResults([]);
        }
    }, [debouncedInputValue, fetchPredictions]);
    var handleSuggestionClick = useCallback(function (placeId) {
        if (!places)
            return;
        var detailRequestOptions = {
            placeId: placeId,
            fields: ['address_components'],
            sessionToken: sessionToken,
        };
        var detailsRequestCallback = function (placeDetails) {
            var _a;
            onPlaceSelect(placeDetails);
            setSelectedPlace(placeDetails);
            setPredictionResults([]);
            setInputValue((_a = placeDetails === null || placeDetails === void 0 ? void 0 : placeDetails.formatted_address) !== null && _a !== void 0 ? _a : '');
            setSessionToken(new places.AutocompleteSessionToken());
        };
        placesService === null || placesService === void 0 ? void 0 : placesService.getDetails(detailRequestOptions, detailsRequestCallback);
    }, [onPlaceSelect, places, placesService, sessionToken]);
    return (<div>
      <div className="autocomplete-container">
        <Combobox as="div" value={selectedPlace} onChange={function (event) {
            return handleSuggestionClick(event.place_id);
        }}>
          {function () {
            return (<div className="relative mt-1">
                <Combobox.Input className={addressDetailsClasses} value={inputValue} onChange={function (event) { return setInputValue(event.target.value); }} placeholder={t('frontend.add_voucher_button.search_address')}/>
                {predictionResults.length > 0 && (<Combobox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                    {predictionResults.map(function (result) { return (<Combobox.Option key={result.place_id} value={result} className={function (_a) {
                            var active = _a.active;
                            return classNames('relative cursor-default select-none py-2 pl-3 pr-9', active ? 'bg-indigo-600 text-white' : 'text-gray-900');
                        }}>
                        {function (_a) {
                            var selected = _a.selected;
                            return (<>
                            <div className="flex">
                              <span className={classNames('truncate', selected && 'font-semibold')}>
                                {result.description}
                              </span>
                            </div>
                          </>);
                        }}
                      </Combobox.Option>); })}
                  </Combobox.Options>)}
              </div>);
        }}
        </Combobox>
      </div>
      <div id="googlemaps-attribution-container"></div>
    </div>);
};
